import {get, put} from "@/api/axios";

// /web/contract/customer/list get
export const list = params => get('/web/contract/customer/list',params)

// /web/contract/customer/view/{id} get
export const view = params => get('/web/contract/customer/view/' + params.id, '')

// /web/contract/customer/node/second/view/{id} get
export const nodeSecondView = params => get('/web/contract/customer/node/second/view/' + params.id, '')

    // /web/contract/customer/node/list get
export const nodeList = params => get('/web/contract/customer/node/list', params)

    // /web/contract/customer/cancel/{id} get
export const cancel = params => get('/web/contract/customer/cancel/' + params.id, '')


/**
 * 签约客户管理-合同-预览(打开新页面)
 * /web/contract/customer/contract/view/{id}
 */
export const contractView = params => get('/web/contract/customer/contract/view/' + params.id, '')

/**
 * 签约客户管理-二手房-确认（节点1）
 * /web/contract/customer/node/second/one/confirm
 */
export const oneConfirm = params => put('/web/contract/customer/node/second/one/confirm', params)

/**
 * 签约客户管理-二手房-确认-缴纳服务费（节点4）
 * /web/contract/customer/node/second/four/confirm/service
 */
export const fourService = params => put('/web/contract/customer/node/second/four/confirm/service', params)

/**
 * 签约客户管理-二手房-确认（节点4）
 * /web/contract/customer/node/second/four/confirm
 */
export const fourConfirm = params => put('/web/contract/customer/node/second/four/confirm', params)

/**
 * 签约客户管理-二手房-确认（节点5）
 * web/contract/customer/node/second/five/confirm
 */
// export const fiveConfirm = params => put('/web/contract/customer/node/second/five/confirm', params)
export const fiveConfirm = params => put('/web/contract/customer/node/second/five/confirm/new', params)


/**
 * 签约客户管理-二手房-确认（节点6）
 * /web/contract/customer/node/second/six/confirm
 */
// export const sixConfirm = params => put('/web/contract/customer/node/second/six/confirm', params)
export const sixConfirm = params => put('/web/contract/customer/node/second/six/confirm/new', params)

/**
 * 签约客户管理-二手房-确认（节点7）
 * /web/contract/customer/node/second/seven/confirm
 */
// export const sevenConfirm = params => put('/web/contract/customer/node/second/seven/confirm', params)
export const sevenConfirm = params => put('/web/contract/customer/node/second/seven/confirm/new', params)


/**
 * 签约客户管理-二手房-确认（节点8）
 * /web/contract/customer/node/second/eight/confirm
 */
// export const eightConfirm = params => put('/web/contract/customer/node/second/eight/confirm', params)
export const eightConfirm = params => put('/web/contract/customer/node/second/eight/confirm/new', params)

/**
 * 签约客户管理-二手房-确认（节点9）
 * /web/contract/customer/node/second/nine/confirm
 */
// export const nineConfirm = params => put('/web/contract/customer/node/second/nine/confirm', params)
export const nineConfirm = params => put('/web/contract/customer/node/second/nine/confirm/new', params)

/**
 * 签约客户管理-二手房-确认（节点10）
 * /web/contract/customer/node/second/ten/confirm
 */
// export const tenConfirm = params => put('/web/contract/customer/node/second/ten/confirm', params)
export const tenConfirm = params => put('/web/contract/customer/node/second/ten/confirm/new', params)

/**
 * 签约客户管理-二手房-确认（节点11）
 * /web/contract/customer/node/second/eleven/confirm
 */
// export const elevenConfirm = params => put('/web/contract/customer/node/second/eleven/confirm', params)
export const elevenConfirm = params => put('/web/contract/customer/node/second/eleven/confirm/new', params)

/**
 * 签约客户管理-二手房-确认（节点12）
 * /web/contract/customer/node/second/twelve/confirm
 */
export const twelveConfirm = params => put('/web/contract/customer/node/second/twelve/confirm', params)


/**
 * 签约客户管理-办公租赁-节点详情
 * /web/contract/customer/node/rent/view/{id}
 */
export const nodeRentView = params => get('/web/contract/customer/node/rent/view/' + params.id, '')

/**
 * 签约客户管理-办公租赁-缴纳佣金（节点3）
 * /web/contract/customer/node/rent/three/commission
 */
export const rentThree = params => put('/web/contract/customer/node/rent/three/commission', params)

/**
 * 签约客户管理-办公租赁-缴纳押金及佣金（节点4）
 * /web/contract/customer/node/rent/four/confirm
 */
// export const rentFour = params => put('/web/contract/customer/node/rent/four/confirm', params)
export const rentFour = params => put('/web/contract/customer/node/rent/four/confirm/new', params)

/**
 * 签约客户管理-办公租赁-验房收房（节点5）
 * /web/contract/customer/node/rent/five/confirm
 */
// export const rentFive = params => put('/web/contract/customer/node/rent/five/confirm', params)
export const rentFive = params => put('/web/contract/customer/node/rent/five/confirm/new', params)


/**
 * 签约客户管理-办公租赁-待归档（节点6）
 * /web/contract/customer/node/rent/six/confirm
 */
export const rentSix = params => put('/web/contract/customer/node/rent/six/confirm', params)

/**
 * 签约客户管理-进程管理-修改
 * /web/contract/customer/process/update
 */
export const update = params => put('/web/contract/customer/process/update', params)

/**
 * 签约客户管理-二手房-审核居间合同
 */
export const intermediaryExamine = params => put('/web/contract/customer/node/second/two/examine/intermediary', params)

/**
 * 签约客户管理-二手房-审核交易合同
 */
export const transactExamine = params => put('/web/contract/customer/node/second/three/examine/transact', params)

/**
 * 签约客户管理-办公租赁-审核居间合同
 */
export const intermediaryRentExamine = params => put('/web/contract/customer/node/rent/one/examine/intermediary', params)

/**
 * 签约客户管理-办公租赁-审核交易合同
 */
export const transactRentExamine = params => put('/web/contract/customer/node/rent/two/examine/transact', params)