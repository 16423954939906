<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap marginBottom20">
          <el-input size="medium" v-model="searchData.name" clearable :maxlength="100" placeholder="输入甲方/乙方姓名" class="marginRight10 marginBottom10 width200"/>
          <el-input size="medium" v-model="searchData.phone" clearable :maxlength="100" placeholder="输入甲方/乙方手机号" class="marginRight10 marginBottom10 width200"/>
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">业务类型 : </span>
            <div class="width200">
              <el-select size="medium" v-model="searchData.businessType" clearable @change="handleNode">
                <el-option label="全部" value="" />
                <el-option label="二手房交易" :value="1" />
                <el-option label="房屋租赁" :value="2" />
              </el-select>
            </div>
          </div>
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">进程节点 : </span>
            <div class="width200">
              <el-select size="medium" v-model="searchData.node" clearable>
                <el-option label="全部" value="" />
                <el-option v-for="(item,index) in nodeList" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">交易状态 : </span>
            <div class="width200">
              <el-select size="medium" v-model="searchData.transactState" clearable >
                <el-option label="全部" value="" />
                <el-option label="正在交易" :value="1" />
                <el-option label="完成交易" :value="2" />
                <el-option label="交易作废" :value="3" />
              </el-select>
            </div>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
        </div>
      </template>
      <template slot="id" slot-scope="{ row }">
        <div v-if="row.businessType === 1 && row.transactState !== 3">
          <el-button type="text" size="mini" :disabled="row.transactState === 3" v-if="row.transactProcessNode > 10" @click="handleContract(row.intermediaryId)">居间合同</el-button>
          <el-button type="text" size="mini" :disabled="row.transactState === 3" v-if="row.transactProcessNode > 15" @click="handleContract(row.transactId)">交易合同</el-button>
        </div>
        <div v-if="row.businessType === 2 && row.transactState !== 3">
          <el-button type="text" size="mini" :disabled="row.transactState === 3" v-if="row.rentProcessNode > 5" @click="handleContract(row.intermediaryId)">居间合同</el-button>
          <el-button type="text" size="mini" :disabled="row.transactState === 3" v-if="row.rentProcessNode > 10" @click="handleContract(row.transactId)">交易合同</el-button>
        </div>
        <div v-if="row.transactState === 3">
          <el-button type="text" size="mini" :disabled="row.transactState === 3">居间合同</el-button>
          <el-button type="text" size="mini" :disabled="row.transactState === 3">交易合同</el-button>
        </div>
      </template>

      <template slot="houseCode" slot-scope="{ row }">
        <div class="flex align-items justify-content-center">
          <div class="marginRight10">{{ row.houseCode }}</div>
          <el-button type="text" size="mini" @click="handleHouseView(row)">查看</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <div class="flex flex-wrap justify-content-center">
          <div class="marginRight10">
        <el-button type="text" size="mini" @click="handleView(row)">交易详情</el-button>
          </div>
          <div v-if="row.transactState === 1">
        <el-button type="text"
                   size="mini"
                   @click="handleNodeEdit(row)"
                   class="marginRight10"
                   v-if="(row.businessType === 1 && row.transactProcessNode > 20 && row.transactProcessNode < 55) || (row.businessType === 2 && row.rentProcessNode > 15 && row.rentProcessNode < 35)">
          进程编辑
        </el-button>
          </div>
        <el-button type="text" size="mini" v-if="row.transactState === 1" @click="handleEdit(row,'edit')">节点编辑</el-button>
        <el-button type="text" size="mini" v-if="row.transactState === 2 || row.transactState === 3" @click="handleEdit(row,'view')">节点详情</el-button>
        <el-button type="text" size="mini" @click="handleJournal(row)">节点日志</el-button>
        <el-button type="text" size="mini" v-if="row.transactState === 1" @click="handleCancel(row)">作废</el-button>
        </div>
      </template>
    </avue-crud>

    <el-dialog
        title="进程编辑"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, cancel, contractView, update } from '@/api/contract/customer'
import { rentProcessNode, transactProcessNode } from './data'
export default {
  name: "index",
  data() {
    return {
      rentProcessNode,
      transactProcessNode,
      nodeList: [],
      searchData: {
        businessType: '',
        name: '',
        phone: '',
        node: '',
        rentProcessNode: '',
        transactProcessNode: '',
        transactState: ''
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '签约客户管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '交易单号',
            prop: 'code',
            width: 150
          },
          {
            label: '业务类型',
            prop: 'businessType',
            dicData: [
              { label: '二手房交易', value: 1 },
              { label: '房屋租赁', value: 2 }
            ],
            width: 150
          },
          {
            label: '签约房源号',
            prop: 'houseCode',
            width: 300
          },
          {
            label: '经纪人姓名',
            prop: 'brokerName',
            width: 150
          },
          {
            label: '经纪人手机号',
            prop: 'brokerPhone',
            width: 150
          },
          {
            label: '甲方角色',
            prop: 'partyARole',
            dicData: [
              { label: '卖方', value: 1 },
              { label: '业主', value: 2 }
            ],
            width: 150
          },
          {
            label: '甲方姓名',
            prop: 'partyAName',
            width: 150
          },
          {
            label: '甲方手机号',
            prop: 'partyAPhone',
            width: 150
          },
          {
            label: '乙方角色',
            prop: 'partyBRole',
            dicData: [
              { label: '买方', value: 1 },
              { label: '租户', value: 2 }
            ],
            width: 150
          },
          {
            label: '乙方姓名',
            prop: 'partyBName',
            width: 150
          },
          {
            label: '乙方手机号',
            prop: 'partyBPhone',
            width: 150
          },
          {
            label: '进程节点',
            prop: 'businessType',
            formatter: function (row, column, cellValue){
              if(row.businessType === 1){
                let text = ''
                transactProcessNode.map(item=>{
                  if(item.value === row.transactProcessNode){
                    return text = item.label
                  }
                })
                return text
              }else if(row.businessType === 2){
                let text = ''
                rentProcessNode.map(item=>{
                  if(item.value === row.rentProcessNode){
                    return text = item.label
                  }
                })
                // const text = rentProcessNode.find(item => item.value === row.rentProcessNode)
                return text
              }
            },
            width: 150
          },
          {
            label: '交易状态',
            prop: 'transactState',
            dicData: [
              { label: '正在交易', value: 1 },
              { label: '完成交易', value: 2 },
              { label: '交易作废', value: 3 }
            ],
            width: 150
          },
          {
            label: '发起时间',
            prop: 'createTime',
            width: 150
          },
          {
            label: '完成时间',
            prop: 'completeTime',
            width: 150
          },
          {
            label: '预览附件',
            prop: 'id',
            width: 200
          }
        ]
      },

      dialogVisible: false,
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '进程状态',
            prop: 'currentNode',
            type: 'select',
            dicData: [],
            span: 24,
            rules: [{
              required: true,
              message: "请选择进程状态",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    handleHouseView(row){
      if(row.businessType === 1){
        this.$router.push({
          path: '/house/second/create',
          query: {
            id: row.houseId,
            type: 'view'
          }
        })
      }else{
        this.$router.push({
          path: '/house/tenancy/create',
          query: {
            id: row.houseId,
            type: 'view'
          }
        })
      }
    },
    // 交易详情
    handleView(row){
      this.$router.push({
        path: '/contract/customer/detail',
        query: {
          id: row.id
        }
      })
    },
    // 节点编辑
    handleEdit(row,type) {
      // console.log('节点编辑',row)
      if(row.businessType === 1){
        this.$router.push({
          path: '/contract/customer/nodeSecondInfo',
          query: {
            id: row.id,
            type: type
          }
        })
      }else{
        this.$router.push({
          path: '/contract/customer/nodeRentInfo',
          query: {
            id: row.id,
            type: type
          }
        })
      }
    },
    // 节点日志
    handleJournal(row){
      this.$router.push({
        path: '/contract/customer/journal',
        query: {
          id: row.id
        }
      })
    },
    // 作废
    handleCancel(row){
      // console.log('作废',row)
      this.$confirm('是否作废此数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancel({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('操作成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    handleContract(id){
      contractView({
        id: id
      }).then(res=>{
        if(res.code === 200){
          window.open(res.data.pageUrl,'_blank')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    handleNode(e){
      // console.log(e)
      if(e === 1){
        this.nodeList = this.transactProcessNode
      }else if(e === 2){
        this.nodeList = this.rentProcessNode
      }else{
        this.searchData.node = '';
        this.nodeList = []
      }
    },
    // 搜索
    async handleSearch(){
      if(this.searchData.businessType === 1){
        this.searchData.transactProcessNode = this.searchData.node
      }
      if(this.searchData.businessType === 2){
        this.searchData.rentProcessNode = this.searchData.node
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        businessType: '',
        name: '',
        phone: '',
        node: '',
        rentProcessNode: '',
        transactProcessNode: '',
        transactState: ''
      }
      this.nodeList = [];
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 进程编辑
    async handleNodeEdit(row){
      await this.setDicData(row);
      this.form.id = row.id;
      this.dialogVisible = true;
    },
    setDicData(row){
      const currentNode = this.findObject(this.formOption.column, 'currentNode');
      if(row.businessType === 1){
        currentNode.dicData = [
          {
            label: '缴纳定金',
            value: 5
          },
          {
            label: '网签及面签',
            value: 6
          },
          {
            label: '缴纳首付',
            value: 7
          },
          {
            label: '解抵押',
            value: 8
          },
          {
            label: '缴税',
            value: 9
          }
        ]
      }else if(row.businessType === 2){
        currentNode.dicData = [
          {
            label: '缴纳押金及租金',
            value: 4
          },
          {
            label: '验房收房',
            value: 5
          }
        ]
      }else{
        currentNode.dicData = []
      }
    },
    submit(form,done){
      // console.log(form)
      update(form).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.onLoad();
          this.dialogVisible = false;
          this.form = {
            id: '',
            currentNode: ''
          }
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    },
    handleClose(){
      this.dialogVisible = false;
      this.form = {
        id: '',
        currentNode: ''
      }
    }
  }
}
</script>

<style scoped>

</style>