export const transactProcessNode = [

    {
        label: '交易信息完善',
        value: 5
    },
    {
        label: '签订居间合同',
        value: 10
    },
    {
        label: '签订买卖合同',
        value: 15
    },
    {
        label: '缴纳服务费及佣金',
        value: 20
    },
    {
        label: '缴纳定金',
        value: 25
    },
    {
        label: '网签及面签',
        value: 30
    },
    {
        label: '缴纳首付',
        value: 35
    },
    {
        label: '解抵押',
        value: 40
    },
    {
        label: '缴税',
        value: 45
    },
    {
        label: '过户',
        value: 50
    },
    {
        label: '物业交割',
        value: 55
    },
    {
        label: '待归档',
        value: 60
    },
    {
        label: '已归档',
        value: 65
    }
]
export const rentProcessNode = [
    {
        label: '签订居间合同',
        value: 5
    },
    {
        label: '签订租赁合同',
        value: 10
    },
    {
        label: '缴纳佣金',
        value: 15
    },
    {
        label: '缴纳押金及佣金',
        value: 20
    },
    {
        label: '验房收房',
        value: 25
    },
    {
        label: '待归档',
        value: 30
    },
    {
        label: '已归档',
        value: 35
    }
]